const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://www.timecard-test.oggi.roots-kk.com/"
    : "http://localhost:8000";
const HTTP_RESPONSE_BAD_REQUEST = 400;
const HTTP_RESPONSE_UNAUTHORIZED = 401;
const HTTP_RESPONSE_FORBIDDEN = 403;
const HTTP_RESPONSE_NOT_FOUND = 404;

const OCCUPATION_OFFICE_WORKER = 1;
const OCCUPATION_STORE_WORKER = 2;
const ACCESS_RIGHT_SYSTEM_MANAGER = 1;
const ACCESS_RIGHT_AUTHORIZER = 2;
const ACCESS_RIGHT_USER = 3;

export {
  API_URL,
  HTTP_RESPONSE_BAD_REQUEST,
  HTTP_RESPONSE_UNAUTHORIZED,
  HTTP_RESPONSE_FORBIDDEN,
  HTTP_RESPONSE_NOT_FOUND,
  OCCUPATION_OFFICE_WORKER,
  OCCUPATION_STORE_WORKER,
  ACCESS_RIGHT_SYSTEM_MANAGER,
  ACCESS_RIGHT_AUTHORIZER,
  ACCESS_RIGHT_USER,
};
