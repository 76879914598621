<template>
  <form class="timecard-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">
      勤怠管理&emsp;
      <span v-if="isCreate">新規登録</span><span v-else>確認・修正</span>
    </h1>
    <div class="row">
      <div class="col-sm-12">
        <h3 class="mx-auto display-5">
          打刻時間<br />{{ timecard.work_start_time }}～{{
            timecard.work_finish_time
              ? timecard.work_finish_time
              : now.slice(0, 5)
          }}
        </h3>
        <h4 class="text-danger fw-bold">※まだ退勤打刻は完了していません</h4>
      </div>
      <div class="col-sm-3 my-auto">
        <h3 class="mb-0">
          勤務時間<br />{{ formatSeconds(form.work_minutes) }}
        </h3>
      </div>
      <div class="col-sm-3 my-auto">
        <h3 class="mb-0">
          休憩時間<br />{{ formatSeconds(form.break_minutes) }}
        </h3>
      </div>
      <div class="col-sm-3 my-auto">
        <h3 class="mb-0">
          遅刻早退時間
          <br />{{ formatSeconds(form.tardy_and_early_leaving_minutes) }}
        </h3>
      </div>
      <div class="col-sm-3 my-auto">
        <h3 class="mb-0">
          残業時間<br />{{
            formatSeconds(form.overtime_minutes + form.midnight_minutes)
          }}
        </h3>
      </div>
    </div>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <h2 class="mb-3">就業予定時間</h2>
    <div class="row">
      <div class="btn-group col-md-12" role="group" aria-label="work_shifts">
        <div
          v-for="shifts in employee.work_shifts"
          :key="shifts.id"
          class="btn-group"
        >
          <input
            class="btn-check"
            type="radio"
            name="work_shifts"
            :id="`shifts-${shifts.id}`"
            :value="{
              shift_start_at: shifts.start_time,
              shift_end_at: shifts.end_time,
            }"
            v-model="form.work_shifts"
            :class="{ 'is-invalid': !!errors.work_shifts }"
          />
          <label
            class="btn btn-outline-success py-3"
            :for="`shifts-${shifts.id}`"
          >
            {{ shifts.start_time.slice(0, 5) }}～{{
              shifts.end_time.slice(0, 5)
            }}
          </label>
          <div v-if="errors.shift_start_at" class="invalid-feedback d-block">
            {{ errors.shift_end_at[0] }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="!!timecard.work_end_at" class="col-md-12">
        <label>入力日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.date"
          :class="{ 'is-invalid': !!errors.date }"
        />
        <div v-if="errors.date" class="invalid-feedback d-block">
          {{ errors.date[0] }}
        </div>
      </div>
    </div>
    <h2 class="mb-3">休憩時間</h2>
    <div class="row">
      <label class="text-muted m-0">本日の休憩時間を選択してください。</label>
      <div class="btn-group col-md-12">
        <div
          class="btn-group col-md-1"
          role="group"
          aria-label="break_minutes"
          v-for="break_min in breakMinutesList"
          :key="break_min"
        >
          <input
            type="radio"
            class="btn-check"
            :class="{ 'is-invalid': !!errors.break_minutes }"
            name="break"
            :id="`break_minutes-${break_min}`"
            :value="break_min"
            v-model="form.break_minutes"
          />
          <label
            class="btn btn-outline-success py-2"
            :for="`break_minutes-${break_min}`"
          >
            {{ break_min }}分
          </label>
          <div v-if="errors.break_minutes" class="invalid-feedback d-block">
            {{ errors.break_minutes[0] }}
          </div>
        </div>
      </div>
    </div>
    <h2 class="mb-3">勤務場所</h2>
    <div class="row">
      <div class="col-md-12">
        <label class="text-muted">自店勤務以外の場合に入力してください。</label>
        <input
          type="text"
          class="form-control"
          v-model="form.support_store"
          :class="{ 'is-invalid': !!errors.support_store }"
        />
        <div v-if="errors.support_store" class="invalid-feedback d-block">
          {{ errors.support_store[0] }}
        </div>
      </div>
    </div>
    <div v-if="form.tardy_and_early_leaving_minutes > 0">
      <h2>遅刻・早退</h2>
      <div class="row">
        <label class="m-0">遅刻早退理由</label>
        <div class="mb-3 btn-group col-md-12">
          <div
            class="btn-group col-md-2"
            role="group"
            aria-label="reasons"
            v-for="reason in reasons"
            :key="reason.value"
          >
            <input
              type="radio"
              class="btn-check"
              name="reasons_id"
              :id="`reasons-${reason.value}`"
              :value="reason.text"
              v-model="form.tardy_and_early_leaving_reasons"
              :class="{
                'is-invalid': !!errors.tardy_and_early_leaving_reasons,
              }"
            />
            <label
              class="btn btn-outline-success py-2"
              :for="`reasons-${reason.value}`"
            >
              {{ reason.text }}
            </label>
          </div>
        </div>
        <div
          v-if="errors.tardy_and_early_leaving_reasons"
          class="invalid-feedback d-block"
        >
          {{ errors.tardy_and_early_leaving_reasons[0] }}
        </div>
        <div class="col-md-12 mb-3">
          <label>遅刻・早退理由(その他)</label>
          <textarea
            cols="2"
            v-model="form.other_tardy_and_early_leaving_reasons"
            :class="{
              'is-invalid': !!errors.other_tardy_and_early_leaving_reasons,
            }"
            :disabled="form.tardy_and_early_leaving_reasons !== 'その他'"
          >
          </textarea>
          <div
            v-if="errors.other_tardy_and_early_leaving_reasons"
            class="invalid-feedback d-block"
          >
            {{ errors.other_tardy_and_early_leaving_reasons[0] }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        employee.need_overtime_calculation &&
        (form.overtime_minutes + form.midnight_minutes > 0) | overtime_reset
      "
    >
      <h2>残業</h2>
      <div class="row">
        <label class="m-0">残業理由</label>
        <div class="mb-3 btn-group col-md-10">
          <div
            class="btn-group col-md-2"
            role="group"
            aria-label="reasons_for_overtime"
            v-for="overtime_reason in reasonsForOvertime"
            :key="overtime_reason.value"
          >
            <input
              class="btn-check"
              type="radio"
              name="overtime_reason_id"
              :id="`overtime_reason-${overtime_reason.value}`"
              :value="overtime_reason.text"
              v-model="form.overtime_reasons"
              :class="{ 'is-invalid': !!errors.overtime_reasons }"
              :disabled="overtime_reset"
            />
            <label
              class="btn btn-outline-success py-2"
              :for="`overtime_reason-${overtime_reason.value}`"
            >
              {{ overtime_reason.text }}
            </label>
          </div>
        </div>
        <div v-if="errors.overtime_reasons" class="invalid-feedback d-block">
          {{ errors.overtime_reasons[0] }}
        </div>
        <div class="col-md-12">
          <label>残業理由(その他)</label>
          <textarea
            cols="2"
            v-model="form.other_overtime_reasons"
            :class="{ 'is-invalid': !!errors.other_overtime_reasons }"
            :disabled="form.overtime_reasons !== 'その他'"
          >
          </textarea>
          <div
            v-if="errors.other_overtime_reasons"
            class="invalid-feedback d-block"
          >
            {{ errors.other_overtime_reasons[0] }}
          </div>
        </div>
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-reset p-3 mt-3"
            :class="{ active: overtime_reset }"
            @click="overtime_reset = !overtime_reset"
          >
            残業時間リセット
          </button>
        </div>
        <div class="col-md-10">
          <label>残業時間リセット理由</label>
          <input
            type="text"
            v-model="form.overtime_reset_reasons"
            class="form-control"
            :class="{ 'is-invalid': !!errors.overtime_reset_reasons }"
            :disabled="!overtime_reset"
          />
          <div
            v-if="errors.overtime_reset_reasons"
            class="invalid-feedback d-block"
          >
            {{ errors.overtime_reset_reasons[0] }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="timecard.work_end_at" class="col-md-12">
        <label>コメント</label>
        <textarea
          cols="2"
          v-model="form.comment"
          :class="{ 'is-invalid': !!errors.comment }"
        >
        </textarea>
        <div v-if="errors.comment" class="invalid-feedback d-block">
          {{ errors.comment[0] }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-around">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/')"
        >
          キャンセル
        </button>
      </div>
      <button type="submit" class="btn btn-primary">&emsp;登録&emsp;</button>
    </div>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "FormView",
  components: { BsAlert },
  data: () => ({
    breakMinutesList: [60, 75, 90, 120, 150, 180, 210, 240],
    kinds: [],
    reasons: [],
    reasonsForOvertime: [],
    errors: {},
    errorMessage: "",
    employee: {},
    timecard: {},
    now: "",
    work_start_minutes: "",
    work_finish_minutes: "",
    overtime_reset: false,
    form: {
      kind: "",
      work_end_at: "",
      break_minutes: "",
      support_store: "",
      total_minutes: "",
      work_minutes: "",
      overtime_minutes: "",
      midnight_minutes: 0,
      tardy_and_early_leaving_minutes: "",
      tardy_and_early_leaving_reasons: "",
      other_tardy_and_early_leaving_reasons: "",
      overtime_reasons: "",
      other_overtime_reasons: "",
      overtime_reset_reasons: "",
      overtime_reset_minutes: 0,
      date: "",
      comment: "",
      shifts_start_time: "",
      shifts_end_time: "",
      work_shifts: {
        shift_start_at: "",
        shift_end_at: "",
      },
    },
    kind: {
      direct_return: 8,
    },
  }),
  computed: {
    isCreate() {
      return this.$route.params.identification === "create";
    },
  },
  watch: {
    overtime_reset(newValue) {
      this.calcWorkMinutes();
      if (newValue) {
        this.form.overtime_reasons = "";
      } else {
        this.form.overtime_reset_reasons = "";
      }
    },
    "form.break_minutes"() {
      this.calcWorkMinutes();
    },
    "form.work_shifts.shift_start_at"() {
      this.calcWorkMinutes();
    },
    "form.work_shifts.shift_end_at"() {
      this.calcWorkMinutes();
    },
    "form.tardy_and_early_leaving_reasons"() {
      if (this.form.tardy_and_early_leaving_reasons !== "その他") {
        this.form.other_tardy_and_early_leaving_reasons = "";
      }
    },
    "form.overtime_reasons"() {
      if (this.form.overtime_reasons !== "その他") {
        this.form.other_overtime_reasons = "";
      }
    },
  },
  mounted() {
    this.$emit("loading", true);

    client
      .get("/api/timecard/timecard/" + this.$route.params.id)
      .then((res) => {
        this.employee = res.data.employee;
        this.timecard = res.data.timecard;
        this.reasons = res.data.reasons;
        this.reasonsForOvertime = res.data.reasons_for_overtime;
        this.kinds = res.data.kinds;
        this.now = res.data.now;
        this.form.break_minutes = 60;
        this.form.work_end_at =
          this.timecard.date +
          " " +
          parseInt(this.now.match(/\d+/g)[0]) +
          ":" +
          parseInt(this.now.match(/\d+/g)[1]);

        for (let i = 0; i < this.employee.work_shifts.length; i++) {
          if (this.employee.work_shifts[i].is_default) {
            this.form.work_shifts = {
              shift_start_at: this.employee.work_shifts[i].start_time,
              shift_end_at: this.employee.work_shifts[i].end_time,
            };
          }
        }
        this.form.kind = !this.timecard.kind ? 1 : this.timecard.kind;
        this.form.date = this.timecard.date;
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        this.$emit("loading", false);
      });
  },
  methods: {
    register() {
      this.$emit("loading", true);
      this.errors = {};
      this.form.shifts_start_time =
        this.timecard.date + " " + this.form.work_shifts.shift_start_at;
      this.form.shifts_end_time =
        this.timecard.date + " " + this.form.work_shifts.shift_end_at;
      let promise =
        this.$route.query.mode == "summary"
          ? client.put(
              `/api/timecard/timecard/${this.$route.params.id}?mode=summary&difference=${this.$route.query.difference}`,
              this.form
            )
          : client.put(
              `/api/timecard/timecard/${this.$route.params.id}`,
              this.form
            );
      promise
        .then(() => {
          this.$emit("loading", false);
          alert("お疲れさまでした。");
          this.$router.push("/");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            this.errorMessage = error.response.data.work_minutes;
          }
          this.$emit("loading", false);
        });
    },
    formatSeconds(minutes) {
      if (!minutes) {
        return "00:00";
      }
      let hour =
        minutes > 0
          ? Math.floor(minutes / 60)
          : "-" + Math.floor((minutes * -1) / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
    roundUpMinutes(minutes) {
      if (this.employee.occupation == 1) {
        return Math.ceil(minutes / 15) * 15;
      } else {
        return Math.ceil(minutes / 30) * 30;
      }
    },
    roundDownMinutes(minutes) {
      if (this.employee.occupation == 1) {
        return Math.floor(minutes / 15) * 15;
      } else {
        return Math.floor(minutes / 30) * 30;
      }
    },
    assignmentZeroMinutes() {
      this.form.work_minutes = 0;
      this.form.total_minutes = 0;
      this.form.tardy_and_early_leaving_reasons = 0;
      this.form.midnight_minutes = 0;
      this.form.overtime_minutes = 0;
    },
    returnMinutes(time) {
      return (
        parseInt(time.match(/\d+/g)[0]) * 60 + parseInt(time.match(/\d+/g)[1])
      );
    },
    calcWorkMinutes() {
      const round_unit = this.employee.occupation == 1 ? 15 : 30;
      this.form.tardy_and_early_leaving_minutes = 0;
      let unit = 0;
      let work_start_at = 0;
      if (this.timecard.work_start_time) {
        let start_at = this.returnMinutes(this.timecard.work_start_time);
        let now = this.returnMinutes(this.now);
        let break_minutes = this.form.break_minutes || 60;
        if (this.form.work_shifts.shift_start_at) {
          let shift_start_at = this.returnMinutes(
            this.form.work_shifts.shift_start_at
          );
          let standard_start_at =
            shift_start_at % round_unit === 0
              ? 0
              : 30 - (shift_start_at % round_unit);
          let shift_end_at = this.returnMinutes(
            this.form.work_shifts.shift_end_at
          );
          let standard_end_at =
            shift_end_at % round_unit === 0
              ? 0
              : 30 - (shift_end_at % round_unit);
          start_at =
            this.roundUpMinutes(start_at + standard_start_at) -
            standard_start_at;
          work_start_at = start_at;
          now = this.roundDownMinutes(now + standard_end_at) - standard_end_at;
          unit = standard_end_at ? 30 - standard_end_at : 0;
          if (this.employee.contract_type != 2) {
            if (start_at < shift_start_at) {
              work_start_at = shift_start_at;
            } else if (this.form.kind != this.kind.direct_return) {
              this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
                start_at - shift_start_at
              );
            }
            this.form.total_minutes = now - work_start_at;
            this.form.work_minutes = this.roundDownMinutes(
              now - work_start_at - break_minutes
            );
            let shift_minutes = shift_end_at - shift_start_at - 60;
            if (
              this.form.tardy_and_early_leaving_minutes === 0 &&
              shift_minutes >= 480 &&
              this.form.work_minutes < 480 &&
              this.form.kind != this.kind.direct_return
            ) {
              this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
                480 - this.form.work_minutes
              );
            } else if (
              shift_minutes >= 480 &&
              this.form.work_minutes +
                this.form.tardy_and_early_leaving_minutes <
                480 &&
              this.form.kind != this.kind.direct_return
            ) {
              this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
                480 -
                  this.form.work_minutes -
                  this.form.tardy_and_early_leaving_minutes
              );
            } else if (
              shift_minutes < 480 &&
              now < shift_end_at &&
              this.form.kind != this.kind.direct_return
            ) {
              this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
                shift_end_at - now
              );
            }
          } else {
            this.form.tardy_and_early_leaving_minutes = 0;
            if (start_at < shift_start_at) {
              work_start_at = shift_start_at;
            }
            this.form.total_minutes = now - work_start_at;
            this.form.work_minutes = this.roundDownMinutes(
              now - work_start_at - break_minutes
            );
          }
        }
        if (
          this.employee.need_overtime_calculation &&
          this.form.kind != this.kind.direct_return &&
          this.form.work_minutes > 480
        ) {
          let overtime_start = work_start_at + 480 + break_minutes;
          if (now <= 1320 + unit) {
            this.form.overtime_minutes = this.roundDownMinutes(
              this.form.work_minutes - 480
            );
            this.form.midnight_minutes = 0;
          } else if (overtime_start >= 1320 + unit) {
            this.form.overtime_minutes = 0;
            this.form.midnight_minutes = this.roundDownMinutes(
              now - 1320 - unit
            );
          } else {
            this.form.overtime_minutes = this.roundDownMinutes(
              1320 + unit - overtime_start
            );
            this.form.midnight_minutes = this.roundDownMinutes(
              now - 1320 - unit
            );
          }
        } else {
          this.form.overtime_minutes = 0;
          this.form.midnight_minutes = 0;
        }
        if (this.overtime_reset) {
          this.form.overtime_reset_minutes =
            this.form.overtime_minutes + this.form.midnight_minutes;
          this.form.overtime_minutes = 0;
          this.form.midnight_minutes = 0;
        } else {
          this.form.overtime_reset_minutes = 0;
        }
      } else {
        this.assignmentZeroMinutes();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
.btn {
  &.btn-reset {
    line-height: 1;
    color: white;
    background-color: #e3598d;

    &:disabled {
      background-color: #aaa;
    }
    &.active {
      background-color: #ff93bc;
    }
  }

  span {
    color: white;
  }
}
</style>
