<template>
  <div class="menu">
    <template v-if="!news || news.length > 0">
      <div class="m-2">お知らせ（{{ news.length }}件）</div>
      <div class="list-group mb-3 border-bottom">
        <a
          v-for="n in news"
          :key="n.id"
          @click="showNews(n)"
          class="list-group-item list-group-item-action"
          aria-current="true"
        >
          <small class="text-muted"> {{ n.date }} </small>&nbsp;
          <span class="badge bg-secondary">{{ n.category_text }}</span>
          &nbsp;{{ n.title }}
        </a>
      </div>
    </template>
    <p
      class="text-danger text-decoration-underline"
      v-if="unapproved_request.length > 0"
      @click="$router.push('/request_list')"
    >
      未承認の申請が<strong class="text-danger">
        &nbsp;{{ unapproved_request.length }}&nbsp;</strong
      >件あります。
    </p>
    <p
      class="text-danger text-decoration-underline"
      v-if="unapproved_timecard.length > 0"
      @click="$router.push('/timecard_summary')"
    >
      未承認の勤怠が<strong class="text-danger">
        &nbsp;{{ unapproved_timecard.length }}&nbsp;</strong
      >件あります。
    </p>
    <p class="text-danger">
      有給休暇&emsp;残：{{ employee.day_count_of_paid_holiday }}日
    </p>

    <section class="mt-4 d-flex justify-content-center">
      <button class="btn btn-start" :disabled="!attendance" @click="startWork">
        <span>出勤</span><br /><span style="font-size: 50%">
          {{ timecard.start_time }}</span
        >
        <p class="fs-6 text-white mb-0 mt-2" style="white-space: pre-line">
          {{ reason }}
        </p>
      </button>
      <button
        class="btn btn-end"
        :disabled="!leaving"
        @click="$router.push(`/timecard/${timecard.id}`)"
      >
        <span>退勤</span><br /><span style="font-size: 50%">
          {{ timecard.end_time }}</span
        >
      </button>
    </section>
    <section class="mt-4 d-flex justify-content-center" v-if="last_summary">
      <div class="fs-4 fw-bold">
        <p class="text-danger">
          ※&ensp;<span class="fs-2 text-danger">{{
            last_summary.close_date
          }}</span
          >&ensp;締め分&ensp;勤怠内容の確定が<span class="fs-2 text-danger"
            >未処理</span
          >です。
        </p>
      </div>
    </section>
    <section
      class="mt-4"
      v-for="(menu, menuIndex) in menuSections"
      :key="menuIndex"
    >
      <h2>{{ menu.title }}</h2>
      <div class="d-flex justify-content-center">
        <a
          class="menu-item"
          v-for="(item, itemIndex) in menu.items"
          :key="`${menuIndex}-${itemIndex}`"
          @click="$router.push(item.link)"
        >
          <div>
            <img :src="item.image" alt="" />
          </div>
          <div class="mt-1">{{ item.title }}</div>
        </a>
      </div>
    </section>

    <bs-modal modal-id="news" ref="newsModal" modal-size="modal-lg">
      <template #title>
        <div class="modal-title">
          <small class="text-muted"> {{ showingNews.date }} </small>&nbsp;
          <span class="badge bg-secondary">
            {{ showingNews.category_text }}
          </span>
          &nbsp;{{ showingNews.title }}
        </div>
        <div>
          <span>{{ showingNews.date }}</span>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="modal_close"
          ></button>
        </div>
      </template>
      <template #body>
        <div style="white-space: pre-wrap">{{ showingNews.body }}</div>
      </template>
    </bs-modal>
  </div>
</template>

<script>
import * as constants from "@/constants";
import client from "@/plugins/axios";
import BsModal from "@/components/Modal.vue";

export default {
  name: "MenuView",
  components: { BsModal },
  data: () => ({
    timecard: "",
    employee: "",
    requests: [],
    attendance: false,
    leaving: false,
    summary: {},
    last_summary: null,
    unapproved_request: [],
    unapproved_timecard: [],
    form: {
      work_start_at: "",
    },
    kind: {
      work_on_holiday: 2,
      public_holiday: 3,
      absence: 6,
      business_trip: 9,
    },
    reason: "",
    news: [],
    showingNews: {},
  }),
  mounted() {
    this.$emit("loading", true);
    client.get("/api/request/unapproved_request").then((res) => {
      this.unapproved_request = res.data;
    });
    client.get("/api/timecard/unapproved_timecard").then((res) => {
      this.unapproved_timecard = res.data;
    });
    client.get("/api/timecard/attendance").then((res) => {
      if (res.data) {
        this.summary = res.data.summary;
        this.last_summary = res.data.last_summary;
        this.timecard = res.data.timecard;
        this.employee = res.data.employee;
        this.requests = res.data.requests;
      }
      this.checkTimecard();
      this.$emit("loading", false);
    });
    client.get("/api/system/news").then((res) => {
      this.news = res.data;
    });
  },
  computed: {
    menuSections() {
      let sections = [
        {
          title: "メニュー",
          items: [
            {
              image: `${process.env.BASE_URL}images/menu/icon_wt.png`,
              title: "勤怠確認",
              link: "/timecard",
            },
            {
              image: `${process.env.BASE_URL}images/menu/icon_exit.png`,
              title: "終了",
              link: "/logout",
            },
          ],
        },
        {
          title: "各種申請",
          items: [
            {
              image: `${process.env.BASE_URL}images/menu/application_public.png`,
              title: "出張",
              link: "/request_form/0?kind=9",
            },
            {
              image: `${process.env.BASE_URL}images/menu/application_holiday.png`,
              title: "有給休暇",
              link: "/request_form/0?kind=4",
            },
            {
              image: `${process.env.BASE_URL}images/menu/application_special.png`,
              title: "特別休暇",
              link: "/request_form/0?kind=5",
            },
            {
              image: `${process.env.BASE_URL}images/menu/application.png`,
              title: "申請一覧",
              link: "/request_list",
            },
          ],
        },
      ];

      const user = this.$store.getters["user/user"];
      if (user.occupation === constants.OCCUPATION_OFFICE_WORKER) {
        sections[1].items.splice(-1, 0, {
          image: `${process.env.BASE_URL}images/menu/application_compensatory_day_off.png`,
          title: "休日出勤",
          link: "/request_form/0?kind=2",
        });
      }
      if (!user.need_overtime_calculation) {
        sections[1].items.splice(-1, 0, {
          image: `${process.env.BASE_URL}images/menu/application_direct_bounce.png`,
          title: "直行直帰",
          link: "/request_form/0?kind=8",
        });
      }
      if (
        user.access_right !== constants.ACCESS_RIGHT_USER ||
        user.has_viewable_department
      ) {
        sections[0].items.unshift({
          image: `${process.env.BASE_URL}images/menu/icon_summary.png`,
          title: "勤怠集計",
          link: "/timecard_summary",
        });
      }
      if (user.is_superuser) {
        sections.push({
          title: "管理者向けメニュー",
          items: [
            {
              image: `${process.env.BASE_URL}images/menu/icon_users.png`,
              title: "従業員管理",
              link: "/employee",
            },
            {
              image: `${process.env.BASE_URL}images/menu/icon_org.png`,
              title: "組織管理",
              link: "/department",
            },
            {
              image: `${process.env.BASE_URL}images/menu/job_mainte.png`,
              title: "マスター管理",
              link: "/choice",
            },
            {
              image: `${process.env.BASE_URL}images/menu/icon_device.png`,
              title: "デバイス管理",
            },
            {
              image: `${process.env.BASE_URL}images/menu/icon_paid_schedule.png`,
              title: "休日設定",
              link: "/holiday",
            },
            {
              image: `${process.env.BASE_URL}images/menu/icon_paid_holiday.png`,
              title: "有休消化状況",
              link: "/paid_holiday",
            },
            {
              image: `${process.env.BASE_URL}images/menu/reports.png`,
              title: "給与連携",
              link: "/salary_export",
            },
          ],
        });
      }

      return sections;
    },
  },
  methods: {
    startWork() {
      this.form.work_start_at = new Date().toLocaleString();
      client.post("/api/timecard/attendance", this.form).then(() => {
        this.$store.dispatch("user/logout");
        this.$router.push({ path: "/login" });
      });
    },
    checkTimecard() {
      if (
        this.summary &&
        !(this.summary.status == 0 || this.summary.status == 2)
      ) {
        this.reason = "勤怠確定後は\n打刻できません。";
      } else if (!this.timecard || this.timecard.kind == 0) {
        this.reason = "入社日以前は\n打刻できません。";
      } else if (
        this.summary &&
        this.summary.status == 2 &&
        this.timecard.kind == this.kind.absence
      ) {
        this.attendance = true;
      } else if (
        this.timecard.holiday_and_weekday > 4 &&
        this.employee.occupation == 1 &&
        this.timecard.kind != this.kind.work_on_holiday &&
        this.timecard.kind != this.kind.business_trip
      ) {
        this.reason = "休日";
      } else if (this.timecard.kind == this.kind.public_holiday) {
        this.reason = "休日";
      } else if (
        this.timecard.kind &&
        ![1, 2, 8].includes(Number(this.timecard.kind))
      ) {
        this.reason = this.timecard.kind_text;
      } else if (!this.timecard.work_start_at && !this.timecard.work_end_at) {
        this.attendance = true;
      } else if (this.timecard.work_start_at && !this.timecard.work_end_at) {
        this.leaving = true;
        if (this.timecard.request && this.timecard.request.action) {
          this.reason = this.timecard.request.action_kind;
        }
      } else {
        if (this.timecard.request && this.timecard.request.action) {
          this.reason = this.timecard.request.action_kind;
        }
      }
    },
    showNews(news) {
      this.showingNews = { ...news };
      this.$refs.newsModal.show();
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  max-height: 100px;
  overflow: auto;
}
.list-group .badge {
  width: 100px;
}

.justify-content-center > .btn,
.justify-content-center > a {
  margin: 0 1.5rem;
}
.btn {
  &.btn-start,
  &.btn-end {
    line-height: 1;
    width: 214px;
    height: 124px;
    font-size: 32px;
    color: white;

    &:disabled {
      background-color: #aaa;
    }
  }

  &.btn-start {
    background-color: #56b9cc;
  }

  &.btn-end {
    background-color: #e3598d;
  }

  span {
    color: white;
  }
}
a.menu-item {
  text-align: center;
  text-decoration-color: #333;
  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  &:hover img {
    transition: 0.3s;
    opacity: 0.8;
    transform: scale(1.05, 1.05);
  }
}
</style>
