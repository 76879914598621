<template>
  <div class="employee-list">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
    </button>
    <h1 class="mb-3">従業員管理&emsp;一覧</h1>

    <div class="content">
      <bs-alert
        v-if="registeredMessage"
        :message="registeredMessage"
        color="success"
        closable
        @close="registeredMessage = ''"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        @close="errorMessage = ''"
      />

      <div class="d-flex mb-3">
        <select
          v-model="filter.enrollment"
          class="form-select mx-2"
          style="width: 100px"
        >
          <option v-for="text in enrollmentSelection" :key="text" :value="text">
            {{ text }}
          </option>
        </select>

        <select
          v-model="filter.department"
          class="form-select mx-2"
          style="width: 300px"
        >
          <option value="">全部署</option>
          <option
            v-for="(value, key) in departments"
            :key="key"
            :value="value.code"
          >
            {{ value.code }}:{{ value.name }}
          </option>
        </select>
        <select2
          v-model="filter.employee"
          class="mx-2"
          style="width: 300px"
          :options="employeeSelection"
          :settings="employeeSelectSettings"
        ></select2>
        <div class="ms-auto d-flex">
          <button
            type="button"
            class="btn btn-primary me-3 d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_import"
          >
            <span class="material-icons text-white me-1">upload_file</span>
            一括登録
          </button>
          <button
            class="btn btn-primary me-3 d-flex align-items-center"
            @click="exportEmployee"
          >
            <span class="material-icons text-white me-1">download</span>
            CSV出力
          </button>
          <button
            type="button"
            class="btn btn-primary me-3 d-flex align-items-center"
            @click="$router.push('/employee/create')"
          >
            <span class="material-icons text-white me-1">add</span>
            新規登録
          </button>
        </div>
      </div>

      <div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th style="width: 170px">社員番号</th>
              <th>氏名</th>
              <th>契約形態</th>
              <th>入社日</th>
              <th>退社日</th>
              <th>部署</th>
              <th>権限</th>
            </tr>
          </thead>
          <tbody v-if="employees.length > 0">
            <tr
              v-for="(employee, index) in filteredEmployee"
              :key="index"
              @click="$router.push(`/employee/${employee.identification}`)"
            >
              <td class="text-center">{{ employee.identification }}</td>
              <td>{{ employee.name }}</td>
              <td>
                <div class="d-flex justify-content-between">
                  {{ employee.latest_contract_type.type_text }}

                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle btn-sm"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                      @click.stop=""
                    >
                      <span class="material-icons text-white me-1">edit</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <button
                          type="button"
                          class="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#contract_type_modal"
                          @click.stop="
                            editId = employee.latest_contract_type.id
                          "
                          v-if="
                            employee.enrollment_text !== '退職者' &&
                            !employee.latest_contract_type.end_at &&
                            employee.latest_contract_type.start_at <= today
                          "
                        >
                          契約形態 変更
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#rejoin_modal"
                          @click.stop="
                            editId = employee.latest_contract_type.id
                          "
                          v-if="employee.enrollment_text === '退職者'"
                        >
                          再入社
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="dropdown-item"
                          @click.stop="leave(employee.identification)"
                          v-if="employee.enrollment_text !== '退職者'"
                        >
                          休業
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ employee.join_at }}
              </td>
              <td class="text-center">
                {{ employee.retirement_at || "－" }}
              </td>
              <td>{{ employee.department_name }}</td>
              <td>{{ employee.access_right_text }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">登録がありません。</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="modal fade modal-lg"
      id="modal_import"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_import"
    >
      <div class="modal-dialog modal-dialog-centered">
        <employee-import-view
          @submit="modal_loading = true"
          @registered="imported"
          @failed="modal_loading = false"
        />
      </div>
    </div>
    <div
      class="modal fade modal-lg"
      id="contract_type_modal"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="contract_type_modal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <contract-type-from-view :id="editId" @registered="registered" />
      </div>
    </div>
    <div
      class="modal fade modal-lg"
      id="rejoin_modal"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="rejoin_modal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <rejoin-form-view :id="editId" @registered="rejoined" />
      </div>
    </div>
  </div>
  <div
    class="modal modal-lg fade"
    id="modal_leave"
    tabindex="-1"
    aria-labelledby="form_title"
    aria-hidden="true"
    ref="modal_leave"
  >
    <div class="modal-dialog modal-dialog-centered">
      <leave-form-view @close="closeModal" :id="editId" />
    </div>
  </div>
  <div v-if="modal_loading" class="loading" style="z-index: 10000">
    <div>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import Select2 from "vue3-select2-component";
import client from "@/plugins/axios";
import EmployeeImportView from "@/views/Employee/ImportView.vue";
import * as bootstrap from "bootstrap";
import * as constants from "@/constants";
import ContractTypeFromView from "@/views/Employee/ContractTypeFromView";
import RejoinFormView from "@/views/Employee/RejoinFormView";
import LeaveFormView from "@/views/Employee/LeaveFormView";

function formatDate(dt) {
  const y = dt.getFullYear();
  const m = ("00" + (dt.getMonth() + 1)).slice(-2);
  const d = ("00" + dt.getDate()).slice(-2);
  return y + m + d;
}
function formatDate2(dt) {
  const y = dt.getFullYear();
  const m = ("00" + (dt.getMonth() + 1)).slice(-2);
  const d = ("00" + dt.getDate()).slice(-2);
  return y + "-" + m + "-" + d;
}
export default {
  name: "EmployeeList",
  components: {
    BsAlert,
    Select2,
    EmployeeImportView,
    ContractTypeFromView,
    RejoinFormView,
    LeaveFormView,
  },
  data: () => ({
    today: "",
    modal_loading: false,
    registeredMessage: "",
    errorMessage: "",
    filter: {
      enrollment: "在籍者",
      department: "",
      employee: "",
    },
    editId: -1,
    enrollmentSelection: ["全員", "在籍者", "退職者"],
    departments: [],
    employees: [],
    employeeSelectSettings: { language: "ja", allowClear: true },
  }),
  computed: {
    employeeSelection() {
      let selection = [];
      this.employees.forEach((item) => {
        selection.push({
          id: item.identification,
          text: `${item.identification}:${item.name}`,
        });
      });
      return selection;
    },
    filteredEmployee() {
      let employees = this.employees;
      if (this.filter.employee) {
        return (employees = employees.filter((e) => {
          return this.filter.employee === e.identification;
        }));
      }
      if (this.filter.department) {
        employees = employees.filter((e) => {
          return this.filter.department == e.department;
        });
      }
      return employees.filter((e) => {
        return (
          this.filter.enrollment === "全員" ||
          this.filter.enrollment === e.enrollment_text
        );
      });
    },
  },
  mounted() {
    this.today = formatDate2(new Date());
    this.init();

    if (this.$route.query.registered) {
      this.registeredMessage = `社員情報を登録しました。(社員番号＝${this.$route.query.registered})`;
    }
    if (this.$route.query.deleted) {
      this.registeredMessage = `${
        this.$route.query.deleted == "contract" ? "契約形態" : "社員"
      }情報を削除しました。`;
    }
  },
  methods: {
    init() {
      this.$emit("loading", true);
      client
        .get("/api/employee/employee")
        .then((res) => {
          this.employees = res.data.employees;
          this.departments = res.data.departments;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    imported() {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_import);
      this.modal_loading = false;
      modal.hide();
      this.registeredMessage = "ユーザーの一括登録が完了しました。";
      this.init();
    },
    registered() {
      const modal = bootstrap.Modal.getInstance(this.$refs.contract_type_modal);
      modal.hide();
      this.registeredMessage = "契約形態情報を更新しました。";
      this.editId = -1;
      this.init(true);
    },
    rejoined() {
      const modal = bootstrap.Modal.getInstance(this.$refs.rejoin_modal);
      modal.hide();
      this.registeredMessage = "再入社を登録しました。";
      this.editId = -1;
      this.init(true);
    },
    exportEmployee: function () {
      this.$emit("loading", true);
      client({
        url: "/api/employee/export",
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const today = formatDate(new Date());
          link.setAttribute("download", `user_information_${today}.csv`);
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
    leave(identification) {
      const myModal = new bootstrap.Modal(
        document.getElementById("modal_leave"),
        {
          keyboard: false,
        }
      );
      this.editId = identification;
      const modalToggle = document.getElementById("modal_leave");
      myModal.show(modalToggle);
    },
    closeModal() {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_leave);
      modal.hide();
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  cursor: pointer;
}
</style>
