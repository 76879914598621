import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "../views/LoginView";
import MenuView from "../views/MenuView";
import HolidayList from "../views/Holiday/ListView.vue";
import store from "../store";
import DepartmentList from "../views/Department/ListView";
import ChoicesMasterList from "../views/ChoicesMaster/ListView";
import EmployeeList from "../views/Employee/ListView.vue";
import EmployeeForm from "../views/Employee/FormView.vue";
import TimecardForm from "../views/Timecard/FormView.vue";
import TimecardList from "../views/Timecard/ListView.vue";
import AttendanceForm from "../views/Attendance/FormView";
import RequestList from "../views/Request/ListView";
import RequestApplicationForm from "../views/Request/ApplicationFormView";
import RequestApprovalForm from "../views/Request/ApprovalFormView";
import PaidHolidayForm from "../views/Request/PaidHolidayFromView";
import ContractTypeFrom from "../views/Employee/ContractTypeFromView";
import TimecardSummaryList from "../views/TimecardSummary/ListView";
import TimecardDetail from "../views/Timecard/DetailView";
import RequestDetail from "../views/Request/DetailView";
import BulkUtdateForm from "@/views/Attendance/BulkUtdateFormView";
import ErrorPage from "@/views/Error";
import ExportView from "@/views/Salary/ExportView";
import PaidHolidayList from "@/views/PaidHoliday/ListView";
import Substitute from "../views/Attendance/SubstituteHolidayChange.vue";
import TardyEarlyLeaving from "@/views/TimecardSummary/TardyEarlyLeaving.vue";

const routes = [
  { path: "/login", name: "login", component: LoginView, meta: { open: true } },
  {
    path: "/logout",
    name: "logout",
    redirect: () => {
      store.dispatch("user/logout");
      return "/login";
    },
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
    meta: { open: true },
    props: true,
  },
  { path: "/", name: "menu", component: MenuView, meta: { open: false } },
  {
    path: "/holiday",
    name: "holiday_list",
    component: HolidayList,
    meta: { open: false },
  },
  {
    path: "/department",
    name: "department_list",
    component: DepartmentList,
    meta: { open: false },
  },
  {
    path: "/employee",
    name: "employee_list",
    component: EmployeeList,
    meta: { open: false },
  },
  {
    path: "/employee/:identification",
    name: "employee_form",
    component: EmployeeForm,
    meta: { open: false },
  },
  {
    path: "/choice",
    name: "choice_list",
    component: ChoicesMasterList,
    meta: { open: false },
  },
  {
    path: "/timecard",
    name: "timecard_list",
    component: TimecardList,
    meta: { open: false },
  },
  {
    path: "/timecard/:id",
    name: "timecard_form",
    component: TimecardForm,
    meta: { open: false },
  },
  {
    path: "/timecard/bulk_update/:id",
    name: "timecard_update_form",
    component: BulkUtdateForm,
    meta: { open: false },
  },
  {
    path: "/timecard_summary",
    name: "timecard_summary",
    component: TimecardSummaryList,
    meta: { open: false },
  },
  {
    path: "/attendance/:id",
    name: "attendance_form",
    component: AttendanceForm,
    meta: { open: false },
  },
  {
    path: "/request_list",
    name: "request_list",
    component: RequestList,
    meta: { open: false },
  },
  {
    path: "/request_form/:id",
    name: "request_form",
    component: RequestApplicationForm,
    meta: { open: false },
  },
  {
    path: "/approval/:id",
    name: "approval_form",
    component: RequestApprovalForm,
    meta: { open: false },
  },
  {
    path: "/paid_holiday/:id",
    name: "paid_holiday_form",
    component: PaidHolidayForm,
    meta: { open: false },
  },
  {
    path: "/contract_type/:id",
    name: "contract_type_form",
    component: ContractTypeFrom,
    meta: { open: false },
  },
  {
    path: "/timecard_detail/:id",
    name: "timecard_detail",
    component: TimecardDetail,
    meta: { open: false },
  },
  {
    path: "/request_detail/:id",
    name: "request_detail",
    component: RequestDetail,
    meta: { open: false },
  },
  {
    path: "/salary_export",
    name: "salary_list",
    component: ExportView,
    meta: { open: false },
  },
  {
    path: "/paid_holiday",
    name: "paid_holiday_list",
    component: PaidHolidayList,
    meta: { open: false },
  },
  {
    path: "/substitute_holiday/:id",
    name: "substitute_holiday",
    component: Substitute,
    meta: { open: false },
  },
  {
    path: "/tardy_early_leaving/",
    name: "/tardy_early_leaving",
    component: TardyEarlyLeaving,
    meta: { open: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => !record.meta.open) &&
    !store.getters["user/isLoggedIn"]
  ) {
    // store.dispatch('alert/show', { variant: 'danger', message: constants.MESSAGE.SESSION_TIMEOUT })
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
