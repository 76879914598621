<template>
  <div class="employee-list">
    <div class="no-print">
      <button
        class="btn btn-secondary mb-3 d-flex align-items-center"
        @click="$router.push('/')"
      >
        <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
      </button>
      <h1 class="mb-3">勤怠管理集計&emsp;一覧</h1>
    </div>
    <div class="content">
      <div class="no-print">
        <bs-alert
          v-if="registeredMessage"
          :message="registeredMessage"
          color="success"
          closable
          @close="registeredMessage = ''"
        />

        <bs-alert
          v-if="errorMessage"
          :message="errorMessage"
          color="danger"
          closable
          @close="errorMessage = ''"
        />
        <div class="d-flex mb-3">
          <div class="form-check form-check-inline fs-5 mt-2">
            <input
              class="form-check-input"
              type="radio"
              name="target"
              id="employee"
              value="1"
              v-model="filter.contract"
            />
            <label class="form-check-label" for="employee"
              >社員・契約社員</label
            >
          </div>
          <div class="form-check form-check-inline fs-5 mt-2">
            <input
              class="form-check-input"
              type="radio"
              name="target"
              id="stuff"
              value="2"
              v-model="filter.contract"
            />
            <label class="form-check-label" for="stuff"
              >パート・アルバイト</label
            >
          </div>
          <input
            type="month"
            class="form-control mx-2"
            style="width: 350px; font-size: larger"
            v-model="filter.month"
            :max="targetMonth"
          />
          <select
            v-model="filter.status"
            class="form-select mx-2"
            style="width: 200px"
          >
            <option value="">全件</option>
            <option v-for="(value, key) in statusList" :key="key" :value="key">
              {{ value }}
            </option>
          </select>
          <select
            v-model="filter.history"
            class="form-select mx-2"
            style="width: 200px"
          >
            <option value="">全件</option>
            <option value="0">勤務実績なし</option>
            <option value="1">勤務実績あり</option>
          </select>
          <select
            v-model="filter.absence_day_count"
            class="form-select mx-2"
            style="width: 150px"
          >
            <option value="">全件</option>
            <option value="0">欠勤あり</option>
          </select>
          <div class="ms-auto d-flex" v-if="user.access_right === 1">
            <button
              class="btn btn-primary me-3 d-flex align-items-center"
              @click="recountTimecard"
              v-if="filteredSummary.length > 0 && filteredSummary[0].status < 4"
            >
              <span class="material-icons text-white me-1">star</span>
              再集計
            </button>
          </div>
          <div class="d-flex" v-if="user.is_superuser">
            <button
              class="btn btn-primary me-3 d-flex align-items-center"
              @click="updatePaidHoliday"
              v-if="filter.contract === '2' && filteredSummary.length > 0"
            >
              有休基準時間登録
            </button>
          </div>
          <div class="d-flex" v-if="user.is_superuser">
            <button
              class="btn btn-primary me-3 d-flex align-items-center"
              @click="clickButton"
              v-if="
                filteredSummary &&
                filteredSummary.length > 0 &&
                filteredSummary[0].status < 4
              "
            >
              一括確定
            </button>
          </div>
          <button
            type="button"
            class="btn btn-primary me-3 d-flex align-items-center"
            @click="exportTimecard(filter.month, filter.contract)"
          >
            <span class="material-icons text-white me-1">download</span>
            CSV出力
          </button>
        </div>
      </div>
      <button
        type="button"
        v-if="user.access_right === 1"
        class="btn btn-primary me-3 mb-3 d-flex align-items-center"
        @click="
          $router.push(
            `/tardy_early_leaving?month=${String(
              filter.month
            )}&contract=${String(filter.contract)}`
          )
        "
      >
        遅刻早退確認
      </button>
    </div>
    <div class="content">
      <div class="table-scroll">
        <table class="table table-hover sticky_table">
          <thead>
            <tr>
              <th>従業員</th>
              <th>出勤日数</th>
              <th>公休日数</th>
              <th>有給休暇<br />日数</th>
              <th>特別休暇<br />日数</th>
              <th>欠勤日数</th>
              <th>実労働<br />時間計</th>
              <th>普通残業<br />時間計</th>
              <th>深夜残業<br />時間計</th>
              <th>遅刻早退<br />時間計</th>
              <th>集計状況</th>
              <th>エラー報告書<br />提出</th>
              <th v-if="user.is_superuser">管理者修正</th>
              <th v-if="filter.contract == 2 && user.is_superuser">
                有休基準時間
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredSummary.length > 0">
            <tr
              v-for="summary in filteredSummary"
              :key="summary.id"
              @click="
                $router.push(
                  `/timecard?month=${String(filter.month).replace(
                    '-',
                    ''
                  )}&employee=${summary.employee.identification}&from=summary`
                )
              "
            >
              <td>
                {{ summary.employee.name }}&emsp;({{
                  summary.employee.identification
                }})
              </td>
              <td class="text-right">{{ summary.working_day_count }}</td>
              <td class="text-right">{{ summary.public_holiday_count }}</td>
              <td class="text-right">{{ summary.paid_holiday_count }}</td>
              <td class="text-right">{{ summary.special_vacation_count }}</td>
              <td class="text-right">{{ summary.absence_day_count }}</td>
              <td class="text-right">{{ summary.work_minutes / 60 }}</td>
              <td class="text-right">
                {{ summary.normal_overtime_minutes / 60 }}
              </td>
              <td class="text-right">
                {{ summary.midnight_overtime_minutes / 60 }}
              </td>
              <td class="text-right">
                {{ summary.tardy_and_early_leaving_minutes / 60 }}
              </td>
              <td>{{ summary.status_text }}</td>
              <td class="text-center">
                <span v-if="summary.has_difference" class="material-icons me-1"
                  >radio_button_unchecked</span
                >
              </td>
              <td
                @click.stop="checkManager(summary.id, summary.status)"
                v-if="user.is_superuser"
              >
                <div class="form-check fs-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="target"
                    :id="`modified_by_manager-${summary.id}`"
                    :checked="summary.modified_by_manager"
                    :disabled="summary.status >= 4"
                  />
                </div>
              </td>
              <td v-if="filter.contract == 2 && user.is_superuser" @click.stop>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  step="0.01"
                  v-model="summary.hour_of_paid_holiday"
                  v-if="summary.paid_holiday_count > 0"
                  :disabled="summary.status >= 4"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="14">勤怠情報がありません。</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="modal modal-lg fade"
      id="modal_confirm"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_confirm"
    >
      <div class="modal-dialog modal-dialog-centered">
        <confirm-form
          :messages="messages"
          @approved="confirmed"
          @close="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import client from "@/plugins/axios";
import ConfirmForm from "@/views/TimecardSummary/ConfirmForm";
import * as bootstrap from "bootstrap";
import * as constants from "@/constants";

export default {
  name: "TimecardSummaryList",
  components: { BsAlert, ConfirmForm },
  data: () => ({
    employees: "",
    user: "",
    departments: "",
    registeredMessage: "",
    errorMessage: "",
    today: "",
    summaryList: [],
    statusList: [],
    messages: [],
    filter: {
      month: "",
      contract: 1,
      status: "",
      history: "",
      absence_day_count: "",
    },
  }),
  computed: {
    targetMonth() {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      let d = dt.getDate();

      m = d >= 21 ? m + 1 : m;
      if (m > 12) {
        m = 1;
        y++;
      }
      return y + "-" + ("00" + m).slice(-2);
    },
    filteredSummary() {
      let summary_list = this.summaryList;
      if (this.filter.status) {
        summary_list = summary_list.filter((s) => {
          return s.status == this.filter.status;
        });
      }
      if (this.filter.history === "0") {
        summary_list = summary_list.filter((s) => {
          return s.service_record === 0;
        });
      } else if (this.filter.history === "1") {
        summary_list = summary_list.filter((s) => {
          return s.service_record > 0;
        });
      }
      if (this.filter.absence_day_count) {
        summary_list = summary_list.filter((s) => {
          return s.absence_day_count > 0;
        });
      }
      return summary_list.filter((s) => {
        return this.filter.contract == 1
          ? s.contract_type.type == 1
          : s.contract_type.type != 1;
      });
    },
  },
  watch: {
    "filter.month"() {
      this.init(this.filter.month);
    },
  },
  mounted() {
    history.pushState(
      { prevUrl: window.location.href },
      null,
      window.location.href
    );
    if (this.$route.query.month) {
      this.filter.month = `${this.$route.query.month.slice(
        0,
        4
      )}-${this.$route.query.month.slice(4, 6)}`;
    } else {
      client
        .get(`/api/timecard/timecard_summary`)
        .then((res) => {
          if (res.data.contract_type && res.data.contract_type.type != 1) {
            this.filter.contract = 2;
          }
          if (res.data.month) {
            this.filter.month = `${res.data.month.slice(
              0,
              4
            )}-${res.data.month.slice(4, 6)}`;
          } else {
            this.filter.month = this.targetMonth;
          }
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    }
    this.user = this.$store.getters["user/user"];
  },
  methods: {
    init(month) {
      this.$emit("loading", true);
      client
        .get(`/api/timecard/timecard_summary?month=${month}`)
        .then((res) => {
          this.summaryList = res.data.summary;
          this.statusList = res.data.status_choices;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    recountTimecard() {
      this.$emit("loading", true);
      client
        .get(`/api/timecard/timecard_recount?month=${this.filter.month}`)
        .then(() => {
          this.$emit("loading", false);
          this.init(this.filter.month);
          this.registeredMessage = "勤怠の再集計が完了しました。";
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    checkManager(id, status) {
      if (status >= 4) {
        return;
      }
      this.$emit("loading", true);
      client
        .delete(`/api/timecard/timecard_checked/${id}`)
        .then(() => {
          this.$emit("loading", false);
          this.init(this.filter.month);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    updatePaidHoliday() {
      this.$emit("loading", true);
      let hour_list = new Array();
      for (let i = this.filteredSummary.length; i > 0; i--) {
        if (
          typeof this.filteredSummary[i - 1].hour_of_paid_holiday == "number"
        ) {
          hour_list.push({
            id: this.filteredSummary[i - 1].id,
            hour_of_paid_holiday:
              this.filteredSummary[i - 1].hour_of_paid_holiday,
          });
        }
      }

      client
        .put("/api/timecard/paid_holiday/0", { data: hour_list })
        .then(() => {
          this.$emit("loading", false);
          this.registeredMessage = "有休基準時間を更新しました。";
          this.init(this.filter.month);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    closeModal(detail) {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_confirm);
      if (detail) {
        this.errorMessage = detail;
      }
      modal.hide();
    },
    clickButton() {
      this.$emit("loading", true);

      client
        .get(
          `/api/timecard/manager/confirm?month=${this.filter.month}&contract=${this.filter.contract}`
        )
        .then((res) => {
          this.messages = res.data.messages;
          console.log(res.data);
          if (this.messages && this.messages.length > 0) {
            this.$emit("loading", false);
            var myModal = new bootstrap.Modal(
              document.getElementById("modal_confirm"),
              {
                keyboard: false,
              }
            );
            var modalToggle = document.getElementById("modal_confirm");
            myModal.show(modalToggle);
          } else {
            client
              .post("/api/timecard/manager/confirm", {
                month: this.filter.month,
                contract: this.filter.contract,
              })
              .then(() => {
                this.$emit("loading", false);
                this.registeredMessage = "勤怠を一括確定しました。";
                this.init(this.filter.month);
              })
              .catch((error) => {
                console.error(error);
                if (
                  error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST
                ) {
                  this.errors = { ...error.response.data };
                  if (this.errors.detail) {
                    this.$emit("close", this.errors.detail);
                  }
                }
                this.$emit("loading", false);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    exportTimecard: function (month, contract) {
      this.$emit("loading", true);
      client({
        url: "/api/timecard/timecard_summary/export",
        method: "GET",
        responseType: "blob",
        params: { month, contract },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `出勤簿_${this.filter.contract}_${this.filter.month}.csv`
          );
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  cursor: pointer;
}
table.table td {
  border: 1px solid #258 !important;
}
@media print {
  button *,
  br,
  div.no-print *,
  .no-print {
    display: none !important;
  }
  thead * {
    -webkit-print-color-adjust: exact;
  }
  .table-scroll {
    margin: 0;
  }
}
@media screen {
  .table-scroll {
    height: 550px;
    overflow: auto;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 2;
  }
  .print-area * {
    display: none;
  }
}
@page {
  size: A4;
  margin: -10px;
  padding: 0;
}
.text-right {
  text-align: right;
}
</style>
