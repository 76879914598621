<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">勤怠管理&emsp;確定</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="modal_close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        style="font-size: 14px"
      />
      <bs-alert
        :message="message"
        color="danger"
        v-for="(message, index) in messages"
        :key="index"
      />

      <div class="d-flex justify-content-around">
        <button
          type="button"
          class="btn btn-secondary px-3"
          @click="closeModal"
        >
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";

export default {
  name: "ConfirmFormView",
  components: { BsAlert },
  props: {
    messages: {
      required: false,
      type: Array,
    },
  },
  data: () => ({
    errors: {},
    errorMessage: "",
  }),
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
