<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">休業登録</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div>
      <bs-alert
        v-if="registeredMessage"
        :message="registeredMessage"
        color="success"
        class="m-3"
        closable
        @close="registeredMessage = ''"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        class="m-3"
        closable
        @close="errorMessage = ''"
      />
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-header">登録内容</div>
        <div class="card-body">
          <form class="leave-setting-form" @submit.prevent="register">
            <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
              {{ errors.non_field_errors[0] }}
            </p>
            <p>休業期間と休業理由を入力してください。</p>
            <div class="mb-3">
              <label>開始日</label>
              <input
                type="date"
                class="form-control"
                v-model="form.start_date"
                :class="{ 'is-invalid': !!errors.start_date }"
                :disabled="disable_start"
              />
              <div v-if="errors.start_date" class="invalid-feedback">
                {{ errors.start_date[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label>終了日</label>
              <input
                type="date"
                class="form-control"
                v-model="form.end_date"
                :class="{ 'is-invalid': !!errors.end_date }"
              />
              <div v-if="errors.end_date" class="invalid-feedback">
                {{ errors.end_date[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label>休業理由</label>
              <textarea
                class="form-control"
                v-model="form.reason"
                :class="{ 'is-invalid': !!errors.reason }"
              >
              </textarea>
              <div v-if="errors.reason" class="invalid-feedback">
                {{ errors.reason[0] }}
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <button type="button" class="btn btn-secondary" @click="init">
                元に戻す
              </button>
              <button type="submit" class="btn btn-primary">
                &emsp;登録&emsp;
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header">休業履歴</div>
        <div class="card-body p-0">
          <table id="history" class="w-100">
            <tr>
              <th>休業期間</th>
              <th>休業理由</th>
              <th>操作</th>
            </tr>
            <tbody v-if="leave_history.length > 0">
              <tr
                v-for="leave_setting in leave_history"
                :key="leave_setting.id"
              >
                <td class="td-nowrap text-center">
                  {{ leave_setting.start_date }}～{{ leave_setting.end_date }}
                </td>
                <td>{{ leave_setting.reason }}</td>
                <td class="td-nowrap m-0 text-center">
                  <button
                    class="btn btn-primary m-0 me-2"
                    @click="editLeave(leave_setting.id)"
                    :disabled="leave_setting.end_date < today"
                  >
                    編集</button
                  ><button
                    class="btn btn-danger m-0"
                    @click="deleteLeave(leave_setting.id)"
                    :disabled="leave_setting.start_date <= today"
                  >
                    削除
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  <span class="float-start">休業履歴がありません。</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "LeaveFormView",
  components: { BsAlert },
  props: {
    id: {
      request: true,
      type: String,
    },
  },
  data: () => ({
    leave_history: [],
    leave_history_id: -1,
    errors: {},
    registeredMessage: "",
    errorMessage: "",
    today: "",
    form: {
      start_date: "",
      end_date: "",
      reason: "",
    },
    disable_start: false,
  }),
  watch: {
    id() {
      this.init();
    },
    leave_history_id() {
      console.log("fdgdfg");
      this.disable_start =
        this.leave_history_id > 0 && this.form.start_date <= this.today;
    },
  },
  mounted() {
    this.today = new Date().toLocaleDateString("sv-SE");
  },
  methods: {
    init() {
      this.$emit("loading", true);
      this.errors = {};
      this.form = {
        start_date: "",
        end_date: "",
        reason: "",
        employee: this.id,
      };
      this.leave_history_id = -1;

      client
        .get(`/api/employee/leave?employee=${this.id}`)
        .then((res) => {
          this.leave_history = res.data;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    editLeave(id) {
      this.$emit("loading", true);
      client
        .get(`/api/employee/leave/${id}`)
        .then((res) => {
          this.form = {
            start_date: res.data.start_date,
            end_date: res.data.end_date,
            reason: res.data.reason,
          };
          this.leave_history_id = res.data.id;
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
    deleteLeave(id) {
      if (!confirm("本当に削除してもよろしいですか？")) {
        return;
      }
      this.$emit("loading", true);
      client
        .delete(`/api/employee/leave/${id}`)
        .then(() => {
          this.registeredMessage = "休業設定を削除しました。";
          this.$emit("loading", false);
          this.init();
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
    register() {
      this.$emit("loading", true);
      let promise =
        this.leave_history_id > 0
          ? client.put(
              `/api/employee/leave/${this.leave_history_id}`,
              this.form
            )
          : client.post("/api/employee/leave", this.form);

      promise
        .then(() => {
          this.registeredMessage = "休業設定を登録しました。";
          this.init();
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
#history th {
  text-align: center;
  border: solid 1px #bbb;
  background-color: #ddd;
}
#history td {
  text-align: right;
  padding: 0.5rem;
  border-bottom: solid 1px #bbb;
  border-top: solid 1px #bbb;
}
.td-nowrap {
  white-space: nowrap;
}
</style>
