<template>
  <div class="request-list">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
    </button>
    <h1 class="mb-3">申請&emsp;一覧</h1>
    <div class="content">
      <div>
        <bs-alert
          v-if="registeredMessage"
          :message="registeredMessage"
          color="success"
          closable
          @close="registeredMessage = ''"
        />

        <bs-alert
          v-if="errorMessage"
          :message="errorMessage"
          color="danger"
          closable
          @close="errorMessage = ''"
        />

        <div class="d-flex mb-3">
          <select
            v-model="filter.kind"
            class="form-select mx-2"
            style="width: 150px"
          >
            <option value="0">全て</option>
            <option v-for="(value, key) in kinds" :key="key" :value="key">
              {{ value }}
            </option>
          </select>

          <select
            v-model="filter.status"
            class="form-select mx-2"
            style="width: 150px"
          >
            <option value="未承認">未承認</option>
            <option value="承認済">承認済</option>
            <option value="却下">却下</option>
          </select>
          <div class="ms-auto d-flex">
            <button
              class="btn btn-primary me-3 d-flex align-items-center"
              @click="$router.push('/request_form/0')"
            >
              <span class="material-icons text-white me-1">add</span>
              新しく申請する
            </button>
          </div>
        </div>
      </div>

      <div class="table-scroll">
        <table class="table table-hover sticky_table">
          <thead>
            <tr>
              <th>申請日</th>
              <th>申請者</th>
              <th>申請種別</th>
              <th>対象日</th>
              <th>申請数</th>
              <th>関連日</th>
              <th>申請理由</th>
              <th>ステータス</th>
              <th>更新日</th>
              <th>承認者</th>
            </tr>
          </thead>
          <tbody v-if="filteredRequests.length > 0">
            <tr
              v-for="request in filteredRequests"
              :key="request.id"
              @click="
                editRequest(
                  request.id,
                  request.employee.identification,
                  request.status
                )
              "
              :class="{ 'bg-secondary': request.status_text === '却下' }"
            >
              <td class="text-center">
                {{ request.request_date }}
              </td>
              <td>{{ request.employee.name }}</td>
              <td v-if="request.kind == 8">
                {{ request.kind_text }}({{ request.action_text }})
              </td>
              <td v-else>{{ request.kind_text }}</td>
              <td>{{ request.start_date.replaceAll("-", "/") }}</td>
              <td>{{ request.days }}日</td>
              <td v-if="request.related_date">
                {{ request.related_date.replaceAll("-", "/") }}
              </td>
              <td v-else></td>
              <td>{{ request.reason }}</td>
              <td>{{ request.status_text }}</td>
              <td>{{ request.updated_date }}</td>
              <td v-if="request.authorizer">{{ request.authorizer.name }}</td>
              <td v-else></td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10">登録がありません。</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import client from "@/plugins/axios";

export default {
  name: "RequestList",
  components: { BsAlert },
  data: () => ({
    registeredMessage: "",
    errorMessage: "",
    kinds: [],
    employee: "",
    filter: {
      kind: 0,
      status: "未承認",
    },
    requests: [],
  }),
  computed: {
    filteredRequests() {
      return this.requests.filter((r) => {
        if (this.filter.kind != 0) {
          return (
            this.filter.kind == r.kind &&
            r.status_text.includes(this.filter.status)
          );
        }
        return r.status_text.includes(this.filter.status);
      });
    },
  },
  mounted() {
    this.init();
    if (this.$route.query.error) {
      this.errorMessage = this.$route.query.error;
    }
    if (this.$route.query.registered) {
      this.registeredMessage = `${this.$route.query.registered}を登録しました。`;
    }
    if (this.$route.query.approved && this.$route.query.status) {
      this.registeredMessage = `${this.$route.query.approved}を${this.$route.query.status}しました。`;
    }
  },
  methods: {
    init() {
      this.$emit("loading", true);
      client
        .get("/api/request/request_list")
        .then((res) => {
          this.requests = res.data.requests;
          this.kinds = res.data.kinds;
          this.employee = res.data.employee;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    editRequest(id, identification, status) {
      if (this.employee.identification == identification && status == 3) {
        window.location.href = "#/request_form/" + id;
      } else if (
        this.employee.identification != identification &&
        status == 1 &&
        this.employee.access_right == 2
      ) {
        window.location.href = "#/approval/" + id;
      } else {
        this.$router.push(`/request_detail/${id}`);
      }
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  cursor: pointer;
}
@media screen {
  .table-scroll {
    height: 600px;
    overflow: auto;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 2;
  }
  .print-area * {
    display: none;
  }
}
@media print {
  button *,
  br,
  div.no-print * {
    display: none !important;
  }
  thead * {
    -webkit-print-color-adjust: exact;
  }
  .table-scroll {
    margin: 0;
  }
}
@page {
  size: A4;
  margin: -10px;
  padding: 0;
}
</style>
