<template>
  <form class="timecard-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/timecard_summary')"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
    </button>
    <h1>遅刻早退確認</h1>
    <bs-alert
      v-if="registeredMessage"
      :message="registeredMessage"
      color="success"
      closable
      @close="registeredMessage = ''"
    />
    <div class="text-right">
      <button type="submit" class="btn btn-primary mb-2 me-3">登録</button>
    </div>
    <div class="table-scroll">
      <table class="table table-hover sticky_table" id="summary">
        <thead>
          <tr>
            <th>日付</th>
            <th>従業員ID</th>
            <th>名前</th>
            <th>開始～終了<br />(実就業時間)</th>
            <th>開始～終了<br />(就業予定時間)</th>
            <th>遅刻早退時間</th>
            <th>遅刻早退リセット<br />時間(分)</th>
          </tr>
        </thead>

        <tbody v-if="timecardList.length > 0">
          <tr v-for="(timecard, index) in timecardList" :key="timecard.id">
            <td>
              {{ timecard.date_and_weekday }}
            </td>
            <td>{{ timecard.employee[0] }}</td>
            <td>{{ timecard.employee[1] }}</td>
            <td>
              {{ timecard.work_start_time }}～{{ timecard.work_finish_time }}
            </td>
            <td>
              {{ timecard.shifts_start_time.slice(0, 5) }} ～
              {{ timecard.shifts_end_time.slice(0, 5) }}
            </td>
            <td>
              {{ formatSeconds(timecard.tardy_and_early_leaving_minutes) }}
            </td>
            <td>
              <input
                type="number"
                v-model="timecard.tardy_and_early_leaving_reset_minutes"
                min="0"
                step="15"
                class="form-control"
                :class="{
                  'is-invalid': errors && errors[index]?.non_field_errors,
                }"
              />
              <div
                v-if="errors && errors[index]?.non_field_errors"
                class="invalid-feedback d-block"
              ></div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="14">勤怠情報がありません。</td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "TardyEarlyLeaving",
  components: { BsAlert },
  data: () => ({
    errors: {},
    timecardList: [],
    registeredMessage: "",
    contract_type: "",
    month: "",
    tardy_and_early_reset: true,
  }),
  watch: {},
  mounted() {
    this.month = `${this.$route.query.month}`;
    this.contract_type = `${this.$route.query.contract}`;
    this.init();
  },
  methods: {
    init() {
      client
        .get(
          `/api/timecard/tardy_early_leaving/${this.month}/${this.contract_type}`
        )
        .then((res) => {
          this.$emit("loading", false);
          this.timecardList = res.data.timecard;
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    formatSeconds(minutes) {
      let hour = Math.floor(minutes / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
    register() {
      this.errors = {};
      this.$emit("loading", true);
      let rest_list = new Array();
      for (let i = 0; i < this.timecardList.length; i++) {
        rest_list.push({
          id: this.timecardList[i].id,
          tardy_and_early_leaving_reset_minutes:
            this.timecardList[i].tardy_and_early_leaving_reset_minutes,
        });
      }

      client
        .put(
          `/api/timecard/tardy_early_leaving/${this.month}/${this.contract_type}`,
          { data: rest_list }
        )
        .then(() => {
          this.$emit("loading", false);
          this.registeredMessage = "遅刻早退時間を修正しました。";
          this.init();
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
          this.errors = {
            ...error.response.data,
          };
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

@media screen {
  .table-scroll {
    height: 450px;
    overflow: auto;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 2;
  }
  .print-area * {
    display: none;
  }
}
</style>
