<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">勤怠管理&emsp;承認</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="modal_close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        style="font-size: 14px"
      />

      <form class="approve-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>
        <div class="row">
          <div
            class="col-12 mb-3"
            v-if="error_timecards && error_timecards.length > 0"
          >
            <p class="px-3 text-danger">
              退勤打刻漏れまたは電車遅延が{{
                error_timecards.length
              }}日分あります。
            </p>
          </div>
          <div class="col-sm-12">
            <ul class="list-group">
              <li
                v-for="(value, key) in error_timecards"
                :key="key"
                class="list-group-item pb-0 list-group-item-danger"
              >
                <p>
                  {{ value.date }}
                </p>
              </li>
            </ul>
          </div>
          <div class="col-12 m-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                name="checked"
                id="timecard-checked"
                v-model="form.checked"
              />
              <label class="form-check-label" for="timecard-checked"
                >勤怠の確認をしました</label
              >
            </div>
          </div>
          <div class="col-sm-12 mx-3 mt-3">
            <label>勤怠集計</label>
          </div>
          <div class="col-sm-12 btn-group">
            <div
              class="btn-group"
              role="group"
              aria-label="status"
              v-for="(value, key) in status"
              :key="key"
            >
              <input
                type="radio"
                class="btn-check"
                :class="{ 'is-invalid': !!errors.status }"
                name="status"
                :id="key"
                :value="key"
                v-model="form.status"
              />
              <label class="btn btn-outline-success py-2" :for="key">
                {{ value }}
              </label>
            </div>
          </div>
          <div v-if="errors.status" class="invalid-feedback d-block">
            {{ errors.status[0] }}
          </div>
          <div class="col-sm-12 mx-3 mt-3">
            <label>差し戻し理由</label>
          </div>
          <div class="col-sm-12">
            <textarea
              cols="2"
              v-model="form.send_back_reason"
              :class="{ 'is-invalid': !!errors.send_back_reason }"
              :disabled="form.status != 2"
            >
            </textarea>
            <div
              v-if="errors.send_back_reason"
              class="invalid-feedback d-block"
            >
              {{ errors.send_back_reason[0] }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around mt-3">
          <button
            type="button"
            class="btn btn-secondary px-3"
            @click="closeModal"
          >
            キャンセル
          </button>
          <button
            type="submit"
            class="btn btn-primary px-3"
            :disabled="!form.status || !form.checked"
          >
            確定
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "ApproveFormView",
  components: { BsAlert },
  props: {
    summary: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    error_timecards: {},
    errors: {},
    form: { send_back_reason: "", status: "", checked: "" },
    errorMessage: "",
    status: { 3: "承認", 2: "差し戻し" },
  }),
  watch: {
    summary(value) {
      this.$emit("loading", true);
      this.errors = {};
      this.form.status = "";
      this.form.send_back_reason = "";
      this.form.checked = false;

      client
        .get(`/api/timecard/absence?summary=${value.id}`)
        .then((res) => {
          this.error_timecards = res.data.error_timecards;
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    "form.status"() {
      if (this.form.status != 2) {
        this.form.send_back_reason = "";
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    register() {
      client
        .put(`/api/timecard/timecard/approve/${this.summary.id}`, this.form)
        .then((res) => {
          let status =
            res.data.status_text === "差し戻し" ? res.data.status_text : "承認";
          this.$emit("registered", status);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            if (this.errors.detail) {
              this.$emit("close", this.errors.detail);
            }
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
#summary {
  table-layout: fixed;
}
#summary th {
  text-align: center;
  border: solid 1px #333;
  background-color: #ccc;
}
#summary td {
  text-align: right;
  padding: 0.5rem;
  border: solid 1px #333;
}
</style>
